<template>
  <!-- 下载页面 -->
  <div>
    <Footer></Footer>
    <!-- 下载页面嵌套 -->
    <div class="content">
      <iframe style="border: none;position: relative;" src="/static/oss_upload/index.html" ref="iframe" width="100%"
        height="700px" scrolling="no"></iframe>
    </div>
    <!-- 弹出框 -->
    <div>
      <el-dialog v-model="dialogSwitch" title="上传文件记录" width="60%">
        <img class="download" style="width: .4rem;" src="./innovation_img/history.png" alt="">
        <el-table :data="tableData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column prop="create_time" label="时间" sortable width="180">
          </el-table-column>
          <el-table-column prop="Chinese_name" label="姓名" sortable width="100" style="text-align: center;">
          </el-table-column>
          <el-table-column prop="file_name" label="文件名称" sortable>
          </el-table-column>
        </el-table>
        <el-pagination class="Page" background @current-change="handleCurrentChange" :page-size="10" :hide-on-single-page="value"
          layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-dialog>
    </div>
    <Bottom></Bottom>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import $ from "jquery";
import { getLogin } from "api/apis.js";

export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      dialogSwitch: false,
      name: "",
      url: "",
      Chinese_name: "",
      tableData: "",
      total: "",
      page: "1",
      limit: "",
      value:"",
    };
  },
  created() {
    var _this = this;
    //这里可放到全局，提供给子 iframe 调用
    window.addTab = function (obj, url) {
      console.log(obj)
      _this.name = obj
      _this.url = url
      console.log(_this.url)
      console.log(_this.$store.state.User.user_id)
      _this.getHttpRelut()
    };

    window.addrecord = function () {
      _this.record()
    };

  },

  mounted() {
    console.log(this.$store.state.User.user_id);
    if (this.$store.state.User == "") {
      setTimeout(() => {
        this.$confirm("您还没有登录,请先进行登录", "提示", {
          closeOnClickModal: true,
          lockScroll: true,
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "已跳转到登录页!",
            });
            this.$router.push("/Register");
          })
          .catch(() => {
            this.$message({
              type: "success",
              message: "已跳转到登录页",
            });
            this.$router.push("/Register");
          });
      }, 2000);
    }
  },
  methods: {
    getHttpRelut(res) {
      //业务逻辑
      let dataobj = new FormData();
      dataobj.append("user_id", this.$store.state.User.user_id);
      dataobj.append("file_name", this.name);
      dataobj.append("url", this.url);
      getLogin.record(dataobj).then((res) => {
        console.log(res);
      });
    },
    // 点击分页
    handleCurrentChange(val) {
      this.page = val
      this.record()
    },
    // 获取上传记录
    record() {
      this.Chinese_name = this.$store.state.User.real_name
      this.dialogSwitch = true
      let dataobj = new FormData();
      dataobj.append("user_id", this.$store.state.User.user_id);
      dataobj.append("page", this.page);
      dataobj.append("limit", 10);

      getLogin.Document(dataobj).then((res) => {
        this.total = res.count
        let data = JSON.parse(res.data)
        data.map((item, index) => {
          item.Chinese_name = this.Chinese_name
        });
        this.tableData = data
        console.log(this.tableData)
      });
    },
  },
};
</script>
  
<style scoped lang="scss">
:deep .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

// :deep .cell{
//   text-align: center
// }
@import "./innovation.scss";
</style>